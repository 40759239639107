const routes = [
  {
    path: "/",
    exact: true,
    component: "Home",
  },
  {
    path: "/terms",
    exact: true,
    component: "TermsOfService",
  },
  {
    path: "/privacy",
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: "/subs",
    exact: true,
    component: "Subs",
  }
];

export default routes;
