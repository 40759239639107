import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Styles } from "../styles/styles";
import routes from "./config";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Routes>
        {routes.map((routeItem) => {
            const Component = lazy(
              () => import(`../pages/${routeItem.component}`)
            );
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              element={<Component />}
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default Router;
